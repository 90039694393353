<div class="registration-top-bar-container">
  <div class="folowed-status-container">
    <button
      class="back"
      [routerLink]="[
        './company/',
        productRegistrationDetails?.productRegistration?.companyId,
        'registrations'
      ]"
    >
      <svg-icon name="arrowToLeft" class="back-icon"></svg-icon>
      <span>
        {{
          productRegistrationDetails?.productRegistration
            ?.productRegistrationNumberString
        }}
      </span>
    </button>

    <div class="status-container" *ngIf="!isAccountManager || isReadonlyField">
      <div class="status" [style.color]="currentColorStatus">
        <span class="pr-1">
          {{ isAccountManager ? activityName : statusName }}
        </span>

        <span *ngIf="isCancelledStatus" class="info-icon-cancel">
          <svg-icon
            name="red-info"
            [ngbPopover]="infoTemplateCancelled"
            placement="bottom-left"
            [autoClose]="'outside'"
            triggers="mouseenter:mouseleave"
            class="info-icon"
          ></svg-icon>
        </span>

        <span *ngIf="isProductPaused">
          <svg-icon
            name="info"
            [ngbPopover]="infoTemplatePaused"
            placement="bottom-left"
            [autoClose]="'outside'"
            triggers="mouseenter:mouseleave"
            class="info-icon"
          ></svg-icon>
        </span>

        <ng-template #infoTemplateCancelled>
          <div class="reason-template">
            <div class="row p-0">
              <div class="col-5 pop-over-label p-0">{{ statusName }} on</div>
              <div class="col-7 pop-over-field p-0">
                {{
                  cancelationDetails?.canceledAt | date: "dd/MM/YYYY hh:mm a"
                }}
              </div>
            </div>
            <div class="row mt-2 p-0">
              <div class="col-5 pop-over-label p-0">{{ statusName }} by</div>
              <div class="col-7 pop-over-field p-0">
                {{ cancelationDetails.canceledBy?.fullName }}
              </div>
            </div>
            <div class="row mt-2 p-0">
              <div class="col-5 pop-over-label p-0">Reason</div>
              <div class="col-7 pop-over-field p-0">
                {{ cancelationDetails.reason }}
              </div>
            </div>
          </div>
        </ng-template>

        <ng-template #infoTemplatePaused>
          <div class="reason-template">
            <div class="row p-0">
              <div class="col-5 pop-over-label p-0">Paused on</div>
              <div class="col-7 pop-over-field p-0">
                {{ pauseDetails?.pausedAt | date: "dd/MM/YYYY hh:mm a" }}
              </div>
            </div>
            <div class="row mt-2 p-0">
              <div class="col-5 pop-over-label p-0">Paused by</div>
              <div class="col-7 pop-over-field p-0">
                {{ pauseDetails?.pausedBy?.fullName }}
              </div>
            </div>
            <div class="row mt-2 p-0">
              <div class="col-5 pop-over-label p-0">Reason</div>
              <div class="col-7 pop-over-field p-0">
                {{ pauseDetails?.reason }}
              </div>
            </div>
          </div>
        </ng-template>
      </div>
    </div>

    <div
      class="status-btn"
      *ngIf="isAccountManager && !isReadonlyField"
      ngbDropdown
      #dropdown="ngbDropdown"
    >
      <div class="status-dropdown row" ngbDropdownToggle>
        <span
          class="status-dropdown-wrd col-9"
          [ngClass]="{ 'lrg-wrd': isLagrWord }"
        >
          {{ activityName }}
        </span>
        <svg-icon
          [ngClass]="{ show: isShown }"
          name="black-arrow-down"
          class="black-arrow-down col-1"
        ></svg-icon>
      </div>
      <div class="dropdown" ngbDropdownMenu>
        <div class="dropdown-content">
          <p class="dropdown-content-title">Post Activity</p>
          <mat-radio-group [(ngModel)]="selectedActivityValue">
            <mat-radio-button
              *ngFor="let activity of nextActivities"
              [value]="activity.id"
              [style.color]="activity.colorCode"
              (click)="sendClickedValueName(activity.name)"
            >
              {{ activity.name }}
              <div *ngIf="hasInputField ">
                <input
                  type="text"
                  *ngIf="selectedActivityValueName == activity.name"
                  [placeholder]="isUploadLicense? 'Enter license number':'Enter Acceptance number'"
                  [(ngModel)]="inputActivityValue"
                />
              </div>
            </mat-radio-button>
          </mat-radio-group>

          <button
            (click)="
              hasInputField && isUploadLicense
                ? uploadLicense()
                : updateActivity()
            "
            class="btn d-flex activity-btn"
            [disabled]="isDisabledActionBtn || isSaving"
          >
            <p class="activity-btn-title">
              {{
                hasInputField && isUploadLicense ? "Upload License" : "Apply"
              }}
            </p>
            <i
              *ngIf="isSaving"
              class="fa fa-circle-o-notch fa-spin activity-btn-spin"
            ></i>
          </button>
        </div>
      </div>
    </div>

    <div class="followed-by-img">
      <img
        [src]="profilePictureUrl"
        *ngIf="profilePictureUrl"
        alt="profile-picture"
        class="profile-picture"
      />
      <div class="default-actor-picture" *ngIf="!profilePictureUrl">
        {{ createdBy | userNameInitials }}
      </div>
    </div>
  </div>

  <div class="actions-countainer">
    <button
      class="resume-paused-btn"
      (click)="pauseResumeProduct()"
      *ngIf="isAccountManager"
      [disabled]="isCancelledStatus"
    >
      <div [class]="isProductPaused ? 'resume-request' : 'pause-request'">
        <i
          [class]="
            isProductPaused
              ? 'fa fa-play-circle resume-shipment-icon'
              : 'fa fa-pause-circle-o hold-shipment-icon'
          "
          aria-hidden="true"
        ></i>
        {{ isProductPaused ? "Resume Request" : "Pause Request" }}
      </div>
    </button>

    <button
      class="cancel-btn"
      [ngClass]="{ 'disabled-cancel-btn': !isNewSystemStatus }"
      (click)="openCancelPopup()"
      [disabled]="!isNewSystemStatus"
    >
      <div class="cancel-icon">
        <svg-icon
          [name]="!isNewSystemStatus ? 'gray-cancel-icon' : 'cancelShipment'"
        ></svg-icon>
        <span [ngClass]="{ 'disabled-cancel-btn': !isNewSystemStatus }">
          Cancel Request
        </span>
      </div>
    </button>
  </div>
</div>
