<div
  class="app-component app-container"
  [ngClass]="!isUserLoggedIn ? 'gradiant-background' : ''"
  [class]="translationService.getCurrentLanguage() + 'Class'"
>
  <ng-container *ngIf="!isUserLoggedIn">
    <div class="promotional-header">
      The Smartest Way to Manage Your Cargo
    </div>
    <div class="shipment-background"></div>
  </ng-container>
  <ngx-toasta></ngx-toasta>
  <app-nav-bar
    *ngIf="isUserLoggedIn && !canSeeAdminSide && !hasAccessToSidebar"
  ></app-nav-bar>

  <app-white-nav-bar
    *ngIf="isUserLoggedIn && (canSeeAdminSide || hasAccessToSidebar)"
  ></app-white-nav-bar>

  <app-nav-sidebar id="sidenav-bar" *ngIf="isUserLoggedIn && canSeeAdminSide">
  </app-nav-sidebar>

  <app-non-admin-sidebar
    *ngIf="isUserLoggedIn && !canSeeAdminSide && hasAccessToSidebar"
  ></app-non-admin-sidebar>

  <div
    class="component-menu"
    [ngClass]="
      !canSeeAdminSide && !hasAccessToSidebar
        ? 'with-blue-bar'
        : 'with-white-bar'
    "
    *ngIf="isUserLoggedIn && registretionDetails"
  >
    <app-registration-top-bar
      [productRegistrationDetails]="registretionDetails"
    ></app-registration-top-bar>
  </div>

  <div
    id="pre-bootstrap"
    *ngIf="!removePrebootScreen"
    [class.prebootShow.app-component]="!isAppLoaded"
    class="app-component prebootStep"
  >
    <div class="messaging">
      <h1>Loaded!</h1>
      <!-- Hide FreePL Brand -->
      <!-- <p>FreePl<span style="font-style: italic">App</span></p> -->
    </div>
  </div>

  <main
    class="app-component container"
    [class]="
      isUserLoggedIn
        ? canSeeAdminSide
          ? 'padding-after-login-is-admin'
          : 'padding-after-login'
        : ''
    "
  >
    <div [class]="isUserLoggedIn ? '' : 'login-page-container'">
      <router-outlet *ngIf="!loading"></router-outlet>
    </div>
    <app-loading-indicator *ngIf="loading"></app-loading-indicator>
    <!-- <div class="app-component footer-height"></div> -->
  </main>
  <!-- Hide FreePL Brand -->
  <!-- <footer
    class="app-component footer fixed-bottom"
    [class]="isUserLoggedIn ? '' : 'login-footer'"
  >
    <div class="container">
      <p class="text-center" [class]="isUserLoggedIn ? '' : 'footer-text'">
        <span style="font-style: italic">Free</span>PlApp &copy; {{ getYear() }}
      </p>
    </div>
  </footer> -->

  <div
    *ngIf="shouldShowLoginModal"
    class="modal fade login-control"
    bsModal
    #loginModal="bs-modal"
    (onShown)="onLoginModalShown()"
    (onHidden)="onLoginModalHidden()"
    (onHide)="onLoginModalHide()"
    [config]="{ backdrop: 'static' }"
    tabindex="-1"
  >
    <div
      class="modal-dialog modal-lg h-75 d-flex flex-column justify-content-center my-0"
    >
      <div class="modal-content">
        <div class="modal-body">
          <app-login #loginControl [isModal]="true"></app-login>
        </div>
      </div>
    </div>
  </div>
</div>
