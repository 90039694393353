<div class="non-admin-sidebar-container text-center">
  <div class="nav-item menu-icon">
    <svg-icon name="menu-sidebar"></svg-icon>
  </div>

  <div
    class="nav-item cursor-pointer"
    ngbDropdown
    *ngIf="
      canCreateQuotes ||
      canCreateShipment ||
      isMedicalOrPharmaceuticalIndustry ||
      hasAccessToProductLibrary ||
      canCreateProductRegistration
    "
  >
    <div ngbDropdownToggle>
      <svg-icon name="plus-sidebar"></svg-icon>
    </div>
    <div ngbDropdownMenu class="creation-popup">
      <div
        class="dropdown-item"
        *ngIf="canCreateQuotes"
        [routerLink]="[
          '/company/' + companyId + '/quotes/createquote/chooseservices'
        ]"
      >
        Quote
      </div>
      <div
        class="dropdown-item"
        *ngIf="canCreateShipment"
        [routerLink]="['/company/' + companyId + '/shipments/createshipment']"
      >
        Shipment
      </div>
      <div
        class="dropdown-item"
        [routerLink]="[
          '/company/' + companyId + '/products-library/create-product/details'
        ]"
        *ngIf="hasAccessToProductLibrary && canCreateProduct"
      >
        Product
      </div>
      <div
        class="dropdown-item"
        *ngIf="
          isMedicalOrPharmaceuticalIndustry && canCreateProductRegistration
        "
        [routerLink]="[
          '/company/' +
            companyId +
            '/registrations/create-product-registration/product-registration-details'
        ]"
      >
        Registration
      </div>
      <div
        class="dropdown-item"
        *ngIf="isMedicalOrPharmaceuticalIndustry"
        [routerLink]="['/company/' + companyId + '/approvals/create-approval']"
      >
        Approval
      </div>
    </div>
  </div>
  <div
    class="nav-item cursor-pointer"
    [routerLink]="[
      '/company/' + companyId + '/' + nonAdminSidebarEnum.Dashboard
    ]"
    [ngClass]="{ selected: isSelectedTab(nonAdminSidebarEnum.Dashboard) }"
  >
    <svg-icon name="dashboard-sidebar"></svg-icon>
  </div>

  <div
    class="nav-item cursor-pointer"
    *ngIf="canViewShipments || canViewShipmentsForSelectedCustomer"
    [routerLink]="[currentShipmentRoute]"
    [ngClass]="{
      selected:
        isSelectedTab(nonAdminSidebarEnum.Shipments) &&
        !isSelectedTab(nonAdminSidebarEnum.OperationalOversight)
    }"
  >
    <svg-icon name="shipments-sidebar"></svg-icon>
  </div>

  <div
    class="nav-item cursor-pointer"
    *ngIf="canViewQuotes"
    (click)="
      onChangeQuotesListView(
        '/company/' + companyId + '/' + nonAdminSidebarEnum.Quotes
      )
    "
    [ngClass]="{
      selected:
        isSelectedTab(nonAdminSidebarEnum.Quotes) &&
        !isSelectedTab(nonAdminSidebarEnum.OperationalOversight)
    }"
  >
    <svg-icon name="quotes-sidebar"></svg-icon>
  </div>

  <div
    class="nav-item"
    ngbDropdown
    [ngClass]="{
      selected:
        isSelectedTab(nonAdminSidebarEnum.Products) ||
        isSelectedTab(nonAdminSidebarEnum.Registrations) ||
        isSelectedTab(nonAdminSidebarEnum.Approvals)
    }"
    *ngIf="
      isMedicalOrPharmaceuticalIndustry ||
      (hasAccessToProductLibrary && canViewProductsList)
    "
  >
    <button
      class="btn btn-link dropdown-button"
      id="productsDropdownMenu"
      ngbDropdownToggle
    >
      <svg-icon name="products-sidebar"></svg-icon>
    </button>
    <div ngbDropdownMenu class="products-popup">
      <div
        class="dropdown-item"
        [routerLink]="['/company/' + companyId + '/products-library']"
        *ngIf="hasAccessToProductLibrary && canViewProductsList"
      >
        Product Library
      </div>
      <div
        class="dropdown-item"
        *ngIf="
          isMedicalOrPharmaceuticalIndustry && canViewRegistrationListAccess
        "
        [routerLink]="['/company/' + companyId + '/registrations']"
      >
        Registrations
      </div>
      <div
        class="dropdown-item"
        *ngIf="isMedicalOrPharmaceuticalIndustry"
        [routerLink]="['/company/' + companyId + '/approvals']"
      >
        Approvals
      </div>
    </div>
  </div>

  <div
    class="nav-item"
    ngbDropdown
    [ngClass]="{
      selected: isSelectedTab(nonAdminSidebarEnum.OperationalOversight)
    }"
    *ngIf="
      canManageShipmentsOperationalOversight ||
      canManageAllQuotesOperationalOversight
    "
  >
    <button
      class="btn btn-link dropdown-button"
      id="productsDropdownMenu"
      ngbDropdownToggle
    >
      <svg-icon name="operational-oversight-sidebar"></svg-icon>
    </button>
    <div ngbDropdownMenu class="products-popup">
      <div
        *ngIf="canManageShipmentsOperationalOversight"
        class="dropdown-item"
        (click)="
          onChangeShipmentsListView(
            '/shipments',
            nonAdminSidebarEnum.OperationalOversight
          )
        "
      >
        Shipments
      </div>
      <div
        *ngIf="canManageAllQuotesOperationalOversight"
        class="dropdown-item"
        (click)="
          onChangeQuotesListView(
            '/quotes',
            nonAdminSidebarEnum.OperationalOversight
          )
        "
      >
        Quotes
      </div>
    </div>
  </div>

  <div
    class="nav-item cursor-pointer"
    *ngIf="canManageForm4Requests"
    [routerLink]="['/form4requests']"
    [queryParams]="{ view: nonAdminSidebarEnum.Form4Requests }"
    [ngClass]="{ selected: isSelectedTab(nonAdminSidebarEnum.Form4Requests) }"
  >
    <svg-icon name="form4-sidebar"></svg-icon>
  </div>
  <div
    class="nav-item cursor-pointer"
    *ngIf="canViewReports"
    [routerLink]="['/reports']"
    [ngClass]="{ selected: isSelectedTab(nonAdminSidebarEnum.Reports) }"
  >
    <svg-icon name="reports-sidebar"></svg-icon>
  </div>
</div>
