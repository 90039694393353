import { Component, OnInit, Input } from '@angular/core';
import { ProductBase } from '../../../shared/models/product-library/product-base.model';
import { ActivatedRoute, Router } from '@angular/router';
import { ProductDetailsRegistrationsAndApprovals } from '../../../product-library/models/product-details-registrations-and-approvals';
import { ProductCategoryEnum } from 'src/app/create-product/enums/product-category.enum';
import { ProductHelperService } from 'src/app/create-product/services/product-helper.service';

@Component({
  selector: 'app-product-details-summary',
  templateUrl: './product-details-summary.component.html',
  styleUrls: ['./product-details-summary.component.scss']
})
export class ProductDetailsSummaryComponent implements OnInit {
  @Input() product: ProductBase;
  @Input() productReference: ProductDetailsRegistrationsAndApprovals;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private productHelperService: ProductHelperService
  ) {}

  ngOnInit(): void {}

  get isEditRoute(): boolean {
    return this.router.url.includes('product-details');
  }

  handleProductInformationRouting(): void {
    if (!this.isEditRoute) {
      this.router.navigate(['../details'], {
        relativeTo: this.route
      });
    } else {
      this.router.navigate(
        ['../../create-product/', this.product?.id, 'edit-product', 'details'],
        { relativeTo: this.route }
      );
    }
  }

  handleComplianceInformationRouting(): void {
    if (!this.isEditRoute) {
      this.router.navigate(['../compliance-information'], {
        relativeTo: this.route
      });
    } else {
      this.router.navigate(
        [
          '../../create-product/',
          this.product?.id,
          'edit-compliance',
          'details'
        ],
        { relativeTo: this.route }
      );
    }
  }

  get isHealthCareOrPharmaceuticalsCategory(): boolean {
    return this.productHelperService.getIsHealthCareOrPharmaceuticalsCategory(
      this.product
    );
  }
}
