<div class="switch-container">
  <div class="switch-title">
    {{ title }}
  </div>
  <div class="switch-btn-container">
    <label class="switch-btn">
      <input type="checkbox" [(ngModel)]="isChecked" />
      <span class="switch-round"></span>
    </label>
  </div>
</div>

<div class="popup-container" [ngClass]="{ show: isChecked }">
  <div class="checkbox-section" *ngFor="let item of permissionsItems">
    <div class="checkboxes-title-container">
      <div class="checkboxes-title">
        <app-checkbox
          class="permission-sub-input"
          [checked]="item.isSelected"
          (click)="changeValue(item)"
        ></app-checkbox>
        <label class="permission-sub-label" for="">
          {{ item.name }}
        </label>
      </div>
    </div>

    <div class="check-boxes-container">
      <div class="check-box-item" *ngFor="let subItem of item.subItems">
        <app-checkbox
          class="permission-sub-input"
          [checked]="subItem.isSelected"
          (click)="changeValue(subItem)"
        ></app-checkbox>
        <label class="permission-sub-label" for="">
          {{ subItem?.name }}
        </label>
      </div>
    </div>
  </div>
</div>
