import { Component, OnInit } from '@angular/core';
import { AppTranslationService } from '../../services/app-translation.service';
import { AlertService, MessageSeverity } from '../../services/alert.service';
import { AuthService } from '../../services/auth.service';
import { NotificationService } from '../../services/notification.service';
import { AccountService } from '../../services/account.service';
import { ActivatedRoute, Router } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { Company } from '../../../company/models/company.model';
import { ShipmentListViewEnum } from 'src/app/shipments/enums/shipment-list-view.enum';
import { CompanyService } from 'src/app/company/services/company.service';
import { ShipmentHelpersService } from 'src/app/shipments/services/shipment-helpers.service';
import { ProductRegistrationHelperService } from 'src/app/create-product-registration/service/product-registration-helper.service';
import { ProductHelperService } from 'src/app/create-product/services/product-helper.service';
import { ApprovalHelperService } from 'src/app/approvals/services/approval-helper.service';
import { CreateQuoteHelperService } from 'src/app/create-quote/services/create-quote-helper.service';

@Component({
  selector: 'app-nav-bar',
  templateUrl: './nav-bar.component.html',
  styleUrls: ['./nav-bar.component.scss']
})
export class NavBarComponent implements OnInit {
  newNotificationCount: number = 0;
  companies: Company[];
  defaultCompany: string;
  defaultCompanyName: string;
  defaultCompanyForm: UntypedFormGroup;

  totalNumberOfCompanies = 0;
  pageIndex = 0;
  pageSize = 6;
  isCompaniesLoading = false;

  shipmentListViewEnum = ShipmentListViewEnum;

  gT = (key: string | Array<string>, interpolateParams?: object) =>
    this.translationService.getTranslation(key, interpolateParams);

  get notificationsTitle(): string {
    if (this.newNotificationCount) {
      return `${this.gT('app.Notifications')} (${
        this.newNotificationCount
      } ${this.gT('app.New')})`;
    } else {
      return this.gT('app.Notifications');
    }
  }

  constructor(
    public translationService: AppTranslationService,
    private accountService: AccountService,
    public authService: AuthService,
    private companyService: CompanyService,
    private alertService: AlertService,
    private notificationService: NotificationService,
    public router: Router,
    private fb: UntypedFormBuilder,
    private shipmentHelpersService: ShipmentHelpersService,
    private activatedRoute: ActivatedRoute,
    private productRegistrationHelperService: ProductRegistrationHelperService,
    private productHelperService: ProductHelperService,
    private approvalHelperService: ApprovalHelperService,
    private createQuoteHelperService: CreateQuoteHelperService
  ) {}

  ngOnInit(): void {
    this.defaultCompany = this.authService.currentUser.defaultCustomerCompany;

    setTimeout(() => {
      if (this.isUserLoggedIn) {
        this.alertService.resetStickyMessage();
      }
    }, 2000);

    if (this.canManageMedicalCompanies || this.canManageNonMedicalCompanies) {
      this.companyService
        .getFreePLEmployeeCustomerCompanies(
          this.authService.currentUser.employeeId,
          this.pageSize,
          this.pageIndex
        )
        .subscribe(data => {
          this.companies = data?.items;
          this.totalNumberOfCompanies = data?.totalItems;
          this.defaultCompanyName = this.companies.find(
            c => c.id.toString() == this.defaultCompany
          )?.name;
          if (!this.defaultCompanyName) {
            this.getCompanyById();
          }
        });
    }
    this.companyService.customerCompanySwitched.subscribe(data => {
      this.defaultCompany = data['companyId'];
      this.defaultCompanyName = this.companies.find(
        c => c.id.toString() == this.defaultCompany
      )?.name;

      if (!this.defaultCompanyName) {
        this.getCompanyById();
      }
    });
  }

  getCompanyById() {
    this.companyService.getCompany(+this.defaultCompany).subscribe(company => {
      this.companies = [company, ...this.companies];
      this.defaultCompanyName = this.companies.find(
        c => c.id.toString() == this.defaultCompany
      )?.name;
    });
  }

  markNotificationsAsRead(): void {
    const recentNotifications = this.notificationService.recentNotifications.filter(
      n => !n.isViewed
    );
    if (recentNotifications.length) {
      this.notificationService
        .readUnreadNotification(recentNotifications.map(n => n.id))
        .subscribe(
          response => {
            for (const n of recentNotifications) {
              n.isViewed = true;
            }
            this.newNotificationCount = recentNotifications.filter(
              n => !n.isViewed
            ).length;
          },
          error => {
            this.alertService.logError(error);
            this.alertService.showMessage(
              'Notification Error',
              'Marking read notifications failed',
              MessageSeverity.error
            );
          }
        );
    }
  }

  get companyLogo() {
    return this.companies?.find(i => i.name == this.defaultCompanyName)?.logo;
  }

  get companyId() {
    return this.companies?.find(i => i.name == this.defaultCompanyName)?.id;
  }

  get defaultIndustryName(): string {
    return this.companies?.find(i => i.name == this.defaultCompanyName)
      ?.industry?.name;
  }

  onChangeDefaultCustomerCompany() {
    const companyId: number = this.companyId;
    if (companyId && companyId > 0)
      this.companyService
        .setDefaultCustomerCompanyForFreePLEmployee(
          this.authService.currentUser.employeeId,
          companyId.toString()
        )
        .subscribe(_ => {
          this.defaultCompany = companyId.toString();
          this.authService.updateDefaultCompanyDataForCurrentUser(
            this.defaultCompany,
            this.defaultIndustryName,
            true
          );
          this.shipmentHelpersService.totalUserDraftShipmentsNumber = null;
        });
  }

  onScroll(event: any) {
    if (
      event?.target?.offsetHeight + event?.target?.scrollTop >=
        event?.target?.scrollHeight - 1 &&
      !this.hasGotAllCompanies &&
      !this.isCompaniesLoading
    ) {
      this.loadCompanies();
    }
  }

  loadCompanies() {
    this.isCompaniesLoading = true;
    this.pageIndex++;
    this.companyService
      .getFreePLEmployeeCustomerCompanies(
        this.authService.currentUser.employeeId,
        this.pageSize,
        this.pageIndex
      )
      .subscribe(data => {
        this.isCompaniesLoading = false;
        let items = data?.items?.filter(
          item => !this.companies.some(c => c.id == item.id)
        );

        this.companies = this.companies.concat(items);
        if (data?.items.length > 0) {
          this.addToCustomerCompanies(data?.items);
        }
      });
  }

  onScrollToEnd() {
    if (!this.hasGotAllCompanies && !this.isCompaniesLoading) {
      this.loadCompanies();
    }
  }

  addToCustomerCompanies(customerCompanies: any[]) {
    let customerCompaniesIds = customerCompanies.map(c => c.id);
    this.authService.updateUserCustomerCompanies(customerCompaniesIds);
  }

  get hasGotAllCompanies(): boolean {
    let totalCompaniesRetrieved = (this.pageIndex + 1) * this.pageSize;
    return totalCompaniesRetrieved >= this.totalNumberOfCompanies;
  }

  get isAdmin() {
    return this.authService.isAdmin;
  }

  get isUserLoggedIn() {
    return this.authService.isLoggedIn;
  }

  get profilePictureUrl() {
    return this.accountService.currentUser.profilePictureUrl;
  }

  get userName(): string {
    return this.authService.currentUser
      ? this.authService.currentUser.userName
      : '';
  }

  get fullName(): string {
    return this.authService.currentUser
      ? this.authService.currentUser.fullName
      : '';
  }

  get employeeId(): string {
    return this.authService.currentUser.employeeId;
  }

  logout(): void {
    this.shipmentHelpersService.clearEmployeeSelectedShipmentViewColumns();
    this.shipmentHelpersService.clearEmployeeShipmentListView();
    this.approvalHelperService.clearEmployeeApprovalListView();
    this.authService.logout();
    this.authService.redirectLogoutUser();
    this.notificationService.removeCachedNotifications();
    this.removeStoredFiltersData();
    this.productRegistrationHelperService.clearEmployeeSelectedColumns();
    this.productHelperService.clearEmployeeSelectedColumns();
    this.shipmentHelpersService.clearLastSelectedShipmentsList();
    this.createQuoteHelperService.clearEmployeeSelectedQuoteView();
    this.createQuoteHelperService.clearEmployeeSelectedColumns();
    this.createQuoteHelperService.clearQuoteFilters();
    this.createQuoteHelperService.clearLastSelectedQuotesLists();
  }

  removeStoredFiltersData() {
    this.shipmentHelpersService.clearShipmentsStoredFiltersInBrowser();
  }

  get isCustomer() {
    return this.authService.isCustomer;
  }

  get canManageForm4Requests(): boolean {
    return this.authService.canManageForm4Requests;
  }

  get hasOperationsOversightManagementPermission(): boolean {
    return this.authService.hasOperationsOversightManagementPermission;
  }

  onChangeShipmentsListView(link: string, queryParams?: string) {
    let indexOfQueryParam = this.router?.url?.indexOf('?');
    let url = this.router?.url;
    if (indexOfQueryParam > -1) {
      url = this.router?.url?.slice(0, indexOfQueryParam);
    }

    let isSameRoute = url === link;

    this.shipmentHelpersService.setLastSelectedShipmentsList(queryParams);
    this.removeStoredFiltersData();
    let navigation = this.router.navigate([link], {
      queryParams: {
        view: queryParams
      }
    });

    if (isSameRoute) {
      navigation.then(() => {
        window.location.reload();
      });
    }
  }

  isCurrentRoute(route: string): boolean {
    let urlTree = this.router.parseUrl(this.router.url);
    urlTree.queryParams = {};
    let currentRoute = urlTree.toString();

    if (currentRoute && currentRoute[0] === '/') {
      currentRoute = currentRoute.slice(1);
    }

    if (route && route[0] === '/') {
      route = route.slice(1);
    }

    let isCurrentRoute = route === currentRoute;
    return isCurrentRoute;
  }

  hasCurrentQueryParams(queryParam?: string): boolean {
    return queryParam === this.activatedRoute?.snapshot?.queryParams?.view;
  }

  get canManageRegistrationAndApprovalServices(): boolean {
    return this.authService.canManageRegistrationAndApprovalServices;
  }

  isDdlActive(route: string): boolean {
    return this.activatedRoute['_routerState'].snapshot.url.includes(route);
  }

  get isMedicalOrPharmaceuticalIndustry(): boolean {
    return this.authService?.isMedicalOrPharmaceuticalIndustry;
  }

  get canViewReports(): boolean {
    return this.authService.canViewReports;
  }

  get canManageMedicalCompanies(): boolean {
    return this.authService.canManageMedicalCompanies;
  }

  get canManageNonMedicalCompanies(): boolean {
    return this.authService.canManageNonMedicalCompanies;
  }

  get canViewQuotes(): boolean {
    return this.authService.canViewQuotes;
  }

  get canViewShipments(): boolean {
    return this.authService.canViewShipments;
  }

  get canViewShipmentsForSelectedCustomer(): boolean {
    return this.authService.canViewShipmentsForSelectedCustomer;
  }

  get currentShipmentRoute(): string {
    if (this.canViewShipmentsForSelectedCustomer) {
      return 'company/' + this.defaultCompany + '/shipments';
    } else if (this.canViewShipments) {
      return '/shipments';
    }
  }

  get hasAccessToProductLibrary(): boolean {
    return this.authService.currentUser.email == 'h.farid@freepl.com';
  }
}
